/* CSS for language dropdown menu */
div.dropdown-menu.show {
  background-color: #003054;
}

a.dropdown-toggle.nav-link {
  color: #003054;
}

a.dropdown-item {
  color: #ffffff;
  letter-spacing: 2px;
  font-family: "Open Sans", sans-serif;
}

a.dropdown-item:active {
  background-color: #e1ad00;
  color: #003054;
}

a.nav-link:active {
  text-decoration: underline;
}

a.nav-link:hover {
  text-decoration: underline;
}
